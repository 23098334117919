<x-dashboard-layout-simplex>
  <x-dashboard-toolbar
    *ngIf="userView.data as user"
    icon="user"
    header="User"
    [subheader]="user.firstName + ' ' + user.lastName"
  >
    <button mat-icon-button (click)="userView.refresh()">
      <fa-icon icon="refresh"></fa-icon>
    </button>

    <button
      xToolbarAction
      mat-flat-button
      type="button"
      color="accent"
      [matMenuTriggerFor]="notificationMenu"
    >
      Reset Password
    </button>

    <mat-menu #notificationMenu>
      <button mat-menu-item (click)="sendResetPasswordEmail()">
        Send Password Reset Notification
      </button>
      <button mat-menu-item (click)="resetUserPassword()">Reset User Password</button>
    </mat-menu>

    <button mat-flat-button xToolbarAction (click)="editUser(user.id)" color="accent">
      <fa-icon icon="edit"></fa-icon>
      Edit User
    </button>
  </x-dashboard-toolbar>
  <x-dashboard-panel position="side" closeable>
    <mat-list *ngIf="userView.data as user">
      <h3 matSubheader>User Detail</h3>
      <mat-divider></mat-divider>
      <mat-list-item>
        <fa-icon matListItemIcon icon="signature"></fa-icon>
        <span matListItemTitle>Name</span>
        <span matListItemLine>{{ user.firstName }}</span>
        <span matListItemLine>{{ user.lastName }}</span>
      </mat-list-item>
      <mat-list-item>
        <fa-icon matListItemIcon icon="at"></fa-icon>
        <span matListItemTitle>Email</span>
        <span matListItemLine>{{ user.email }}</span>
      </mat-list-item>
      <mat-list-item>
        <fa-icon matListItemIcon icon="phone"></fa-icon>
        <span matListItemTitle>Phone</span>
        <span matListItemLine>{{ user.phone }}</span>
      </mat-list-item>
      <mat-list-item>
        <fa-icon matListItemIcon icon="store"></fa-icon>
        <span matListItemTitle>Default channel</span>
        <span matListItemLine>{{ user.defaultChannel?.name ?? 'None' }}</span>
      </mat-list-item>
      <mat-list-item>
        <fa-icon matListItemIcon icon="birthday-cake"></fa-icon>
        <span matListItemTitle>Birthday</span>
        <span matListItemLine>{{ (user.birthday | date) ?? 'Unknown' }}</span>
      </mat-list-item>
      <mat-list-item>
        <fa-icon matListItemIcon [icon]="user.gender === 'm' ? 'mars' : 'venus'"></fa-icon>
        <span matListItemTitle>Gender</span>
        <span matListItemLine>
          {{ user.gender === 'm' ? 'Male' : user.gender === 'f' ? 'Female' : 'Unknown' }}
        </span>
      </mat-list-item>
      <mat-list-item>
        <fa-icon matListItemIcon icon="user-shield"></fa-icon>
        <span matListItemTitle>Roles</span>
        <span matListItemLine>
          <x-pill *ngFor="let role of user.roles">
            <div class="mat-body">
              {{ role.name }}
            </div>
          </x-pill>
        </span>
        <button mat-button matListAction (click)="editRoles(user.id)">Edit</button>
      </mat-list-item>
    </mat-list>
  </x-dashboard-panel>
  <x-dashboard-panel [panelLoading]="userView.fetchLoading" [panelError]="userView.fetchError">
    <mat-tab-group class="user-form-tabs">
      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon icon="clipboard-list"></fa-icon>
          Orders
        </ng-template>

        <x-dashboard-layout-simplex *matTabContent>
          <x-dashboard-panel position="side" *ngIf="userView.data as user">
            <mat-list>
              <h3 matSubheader>User Sales</h3>
              <mat-divider></mat-divider>
              <mat-list-item *ngFor="let stat of user.orderSalesStats">
                <div matLine>Total Paid: {{ stat.sumPaidTotal | money : stat.currency }}</div>
                <div matLine>
                  Total Refunded: {{ stat.sumRefundedTotal | money : stat.currency }}
                </div>
              </mat-list-item>
              <mat-list-item *ngIf="user.orderSalesStats.length === 0">
                No Sales Statistics
              </mat-list-item>
            </mat-list>

            <mat-selection-list [formControl]="orderStateControl" [multiple]="true">
              <h3 matSubheader>User Order Counts</h3>
              <mat-divider></mat-divider>
              <mat-list-option
                *ngFor="let stateCounts of user.orderStateCounts"
                [value]="stateCounts.state"
              >
                <div matLine>
                  {{ 'OrderState.' + stateCounts.state | l }}: {{ stateCounts.count }}
                </div>
              </mat-list-option>
              <mat-list-item *ngIf="user.orderStateCounts.length === 0">No Orders</mat-list-item>
            </mat-selection-list>
          </x-dashboard-panel>
          <x-dashboard-panel
            [panelLoading]="orderView.fetchLoading"
            [panelError]="orderView.fetchError"
          >
            <x-order-table xPanelContent [view]="orderView"></x-order-table>
            <x-data-table-controls xPanelFooter [view]="orderView"></x-data-table-controls>
          </x-dashboard-panel>
          <x-dashboard-panel
            closeable
            [closed]="!(orderView.displayOptions?.showQuickView ?? false)"
            position="side-m"
            (closeChange)="showQuickViewControl.setValue(!$event.isClosed)"
          >
            <x-order-quick-view [view]="detailView"></x-order-quick-view>
          </x-dashboard-panel>
        </x-dashboard-layout-simplex>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon icon="circle-check"></fa-icon>
          Agreements
        </ng-template>
        <x-dashboard-panel *matTabContent>
          <x-agreement-table xPanelContent [view]="agreementView"></x-agreement-table>
          <x-data-table-controls xPanelFooter [view]="agreementView"></x-data-table-controls>
        </x-dashboard-panel>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon icon="address-book"></fa-icon>
          Address book
        </ng-template>
        <x-dashboard-panel *matTabContent>
          <x-action-row xPanelHeader *ngIf="userView.data as user">
            <button type="button" mat-button (click)="createAddress(user.id)">
              <fa-icon icon="add"></fa-icon>
              Add Address
            </button>
          </x-action-row>
          <x-address-table
            *ngIf="userView.data as user"
            xPanelContent
            [view]="addressView"
            [userId]="user.id"
          ></x-address-table>
          <x-data-table-controls xPanelFooter [view]="addressView"></x-data-table-controls>
        </x-dashboard-panel>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon icon="wallet"></fa-icon>
          Wallets
        </ng-template>
        <x-dashboard-panel *matTabContent>
          <x-wallet-table xPanelContent [view]="walletView"></x-wallet-table>
          <x-data-table-controls xPanelFooter [view]="walletView"></x-data-table-controls>
        </x-dashboard-panel>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon icon="wallet"></fa-icon>
          Payment Methods
        </ng-template>
        <x-dashboard-panel *matTabContent>
          <x-user-payment-method-table
            xPanelContent
            [view]="paymentMethodView"
          ></x-user-payment-method-table>
          <x-data-table-controls xPanelFooter [view]="paymentMethodView"></x-data-table-controls>
        </x-dashboard-panel>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon icon="id-card"></fa-icon>
          Subscriptions
        </ng-template>
        <x-dashboard-panel *matTabContent>
          <x-subscriber-table xPanelContent [view]="subscriberView"></x-subscriber-table>
          <x-data-table-controls xPanelFooter [view]="subscriberView"></x-data-table-controls>
        </x-dashboard-panel>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon icon="id-card"></fa-icon>
          Memberships
        </ng-template>
        <x-dashboard-panel *matTabContent>
          <x-member-table xPanelContent [view]="memberView"></x-member-table>
          <x-data-table-controls xPanelFooter [view]="memberView"></x-data-table-controls>
        </x-dashboard-panel>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon icon="ticket-alt"></fa-icon>
          Vouchers
        </ng-template>
        <x-dashboard-panel *matTabContent>
          <x-voucher-table xPanelContent [view]="voucherView"></x-voucher-table>
          <x-data-table-controls xPanelFooter [view]="voucherView"></x-data-table-controls>
        </x-dashboard-panel>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon icon="heart"></fa-icon>
          Wishlist
        </ng-template>
        <x-dashboard-panel *matTabContent>
          <x-action-row xPanelHeader *ngIf="userView.data as user">
            <button type="button" mat-button (click)="addWishlistItems()">
              <fa-icon icon="add"></fa-icon>
              Add Items
            </button>
          </x-action-row>
          <x-product-wishlist-items-table
            xPanelContent
            (removeItem)="removeWishlistItem($event)"
            [view]="wishlistItemsView"
          ></x-product-wishlist-items-table>
          <x-data-table-controls xPanelFooter [view]="wishlistItemsView"></x-data-table-controls>
        </x-dashboard-panel>
      </mat-tab>
    </mat-tab-group>
  </x-dashboard-panel>
</x-dashboard-layout-simplex>
