import { LanguageDeclaration } from '@x/common/locale';

export const USER_WALLET_INDEX_COLUMNS = {
  id: 'ID',
  createdAt: 'Created at',
  currency: 'Currency',
  balance: 'Total',
};

export const USER_WALLET_INDEX_SORT_COLUMNS = {
  id: 'ID',
  createdAt: 'Created at',
  currency: 'Currency',
  balance: 'Total',
};

export const USER_WALLET_INDEX_LANGUAGE: LanguageDeclaration = {
  userWalletIndex: {
    title: 'Wallets',
    subtitle: '',
    column: { ...USER_WALLET_INDEX_COLUMNS },
    sort: { ...USER_WALLET_INDEX_SORT_COLUMNS },
    action: {
      view: 'View Wallet',
      credit: 'Credit Wallet',
    },
  },
};
